import authService from '../../../../services/auth'
import { AUTH_MUTATIONS } from '../../../../store/mutations'
import {getUserType } from '../../../../misc/auth'
export default {
    data() {
        return {
            dataPhoto: '',
            user: {
                fname: '',
                lname: '',
                email: '',
                title: '',
                location: '',
                phone: '',
                photo: '',
                org_id: 0
            },
            Usertype: getUserType(),
            Org_type: ''
        }
    },
    computed: {
    },
    mounted() {
        this.fetchProfile()
    },
    methods: {
        fetchProfile() {
            authService.getUserProfile().then(res => {
                if (!res.error) {
                    this.user = res.data.d
                    this.acceptNumber()
                    if (res.data.d.photo) {
                        this.dataPhoto = 'data:image;base64,' + res.data.d.photo
                    }
                    this.fetchOrgById(this.user.org_id)
                }
            })
        },
        fetchOrgById(id) {
            authService.GetOrganizationById(id).then(res => {
                if (!res.error) {
                    this.Org_type = res.data.d.type
                }
            })
        },
        acceptNumber() {
            var x = this.user.phone.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.user.phone =  (x[1] ? x[1] + '-' : '') + x[2] + (x[3] ? '-' + x[3] : '')
        },
        renderFileImg() {
            let extAllow = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/ifif', 'image/pjpeg', 'image/pjp']
            let img = this.$refs.fileimg.files[0]
            if (!extAllow.includes(img.type)) {
                return
            }
            let reader = new FileReader()

            reader.readAsDataURL(img)
            reader.onload = e => {
                this.dataPhoto = e.target.result
                if (this.dataPhoto) {
                    this.user.photo = img
                }
            }
        },
        update() {
            let formData = new FormData()
            formData.append('firstName', this.user.fname)
            formData.append('lastName', this.user.lname)
            formData.append('title', this.user.title ? this.user.title.trim() : '')
            formData.append('location', this.user.location ? this.user.location.trim() : '')
            formData.append('phoneNumber', this.user.phone)
            formData.append('profilePhoto', this.user.photo)

            authService.updateProfile(formData).then(res => {
                if (!res.error) {
                    authService.getUserProfile().then(resp => {
                        if (!resp.error) {
                            this.$store.commit(AUTH_MUTATIONS.UPDATE_USER_PROFILE, resp.data.d)
                            this.$router.push({name: 'Account'})
                        }
                    })
                }
            })
        },
        cancel() {
            this.$router.push({name: 'Account'})
        }
    }
}